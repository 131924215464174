import axios from "axios";

const couponService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/coupon`,
});

const CouponServices = {
  getAll: (token: string, search: string) =>
    couponService.get(`/all?search=${search}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  redeem: (id: number, token: string, data?: { confirm_code: string }) =>
    couponService.post(`${id}/redeem`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  redeemSpecial: (id: number, token: string, data: { point: number, confirm_code?: string }) =>
    couponService.post(`${id}/redeem-special`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getHistory: (token: string) =>
    couponService.get(`/history`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CouponServices;
