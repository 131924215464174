import React, { useMemo, useState } from "react";

import dayjs from "dayjs";
import { Helmet } from "react-helmet";
import { IOGTimeSlot } from "Interfaces/OGTimeSlot";
import { IOGService } from "Interfaces/OGService";
import { IOGCreateBooking } from "Interfaces/OGBooking";
import Summary from "components/ogBooking/Summary";
import BookingInfo from "components/ogBooking/BookingInfo";

export const BookingContext = React.createContext({
  timeSlots: [] as IOGTimeSlot[],
  services: [] as IOGService[],
  step: 1,
  input: {
    first_name: "",
    last_name: "",
    email: "",
    tel: "",
    services: [] as number[],
    start_slot_time_id: null as number | null,
    booking_date: dayjs(new Date()).format("YYYY-MM-DD"),
  },
  handleInput: (name: string, value: any) => {},
  onChangeStep: (stepProps: number) => {},
  onSetServices: (services: IOGService[]) => {},
  onSetTimeSlots: (timeSlots: IOGTimeSlot[]) => {},
});

const Booking = () => {
  const [timeSlots, setTimeSlots] = useState<IOGTimeSlot[]>([]);
  const [services, setServices] = useState<IOGService[]>([]);
  const [step, setStep] = useState<number>(1);
  const [input, setInput] = useState<IOGCreateBooking>({
    first_name: "",
    last_name: "",
    email: "",
    tel: "",
    services: [] as number[],
    start_slot_time_id: null as number | null,
    booking_date: dayjs(new Date()).format("YYYY-MM-DD"),
  });

  const handleInput = (name: string, value: any) => {
    setInput({
      ...input,
      [name]: value,
    });
  };

  const onChangeStep = (stepProps: number) => {
    setStep(stepProps);
  };

  const onSetServices = (services: IOGService[]) => {
    setServices(services);
  };

  const onSetTimeSlots = (timeSlots: IOGTimeSlot[]) => {
    setTimeSlots(timeSlots);
  };

  const StepComponent = useMemo(() => {
    switch (step) {
      case 2:
        return <Summary />;
      default:
        return <BookingInfo />;
    }
  }, [step]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Booking - Club Fitting</title>
      </Helmet>
      <div className="min-h-screen px-[27px] py-[21px] bg-[#109747]">
        <div className="relative min-h-[calc(100vh_-_42px)] pt-[33px] pb-[15px] px-[15px] bg-white rounded-lg overflow-hidden drop-shadow-[0_12px_12px_rgba(43,43,43,0.1)]">
          <BookingContext.Provider
            value={{
              timeSlots,
              services,
              step,
              input,
              handleInput,
              onChangeStep,
              onSetServices,
              onSetTimeSlots,
            }}
          >
            {StepComponent}
          </BookingContext.Provider>
        </div>
      </div>
    </>
  );
};

export default Booking;
