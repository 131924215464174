import axios from "axios";

const ogServiceService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/og-service`,
});

const OGServiceServices = {
  getAll: () => ogServiceService.get(`/list`),
};

export default OGServiceServices;
