import { useContext, useMemo, useState } from "react";

import Swal from "sweetalert2";
import dayjs from "dayjs";
import { formatPhoneNumber } from "util/common";
import liff from "@line/liff/dist/lib";
import { BookingContext } from "pages/Booking";
import OGBookingServices from "services/OGBooking";

const Summary = () => {
  const { onChangeStep, input, services, timeSlots } =
    useContext(BookingContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const time = useMemo(() => {
    let min = 0;
    input.services.forEach((s) => {
      services.forEach((sv) => {
        if (s === sv.id) {
          min += sv.duration;
        }
      });
    });
    //add
    let slot = Math.trunc(min / 30);
    if (min % 30 > 0) {
      slot += 1;
    }
    min = slot * 30;
    //add
    let startTime = timeSlots.filter(
      (ts) => ts.id === input.start_slot_time_id
    )[0];
    let dateTimeStart = new Date("1970-01-01T" + startTime.time_start);
    let dateTimeEnd = dayjs(
      new Date(dateTimeStart.getTime() + min * 60 * 1000)
    ).format("HH:mm");
    return `${startTime.time_start.substring(0, 5)} - ${dateTimeEnd}`;
  }, [input, services, timeSlots]);

  const onBooking =  () => {
    if (!isLoading) {
      setIsLoading(true);
      Swal.fire({
        icon: "question",
        html: `<p class="label-m">Are you sure you want to confirm this booking?</p>`,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        confirmButtonColor: "#109747",
        cancelButtonText: `<span class="text-[#109747]">Cancel</span>`,
        reverseButtons: true,
        cancelButtonColor: "white",
      }).then(async(result) => {
        if (result.isConfirmed) {
          let dataProps =  {
            line_id: "",
            first_name: input.first_name,
            last_name: input.last_name,
            email: input.email || undefined,
            tel: input.tel,
            booking_date: input.booking_date,
            start_slot_time_id: input.start_slot_time_id as number,
            services: input.services,
          }
          await liff
            .init({ liffId: `${process.env.REACT_APP_LIFF}` })
            .catch((err) => {
              console.log(err);
            });
          if (liff.isLoggedIn()) {
            let lineProfile = await liff.getProfile();
            dataProps.line_id = lineProfile.userId;
          }
          OGBookingServices.booking(dataProps)
            .then((res) => {
              Swal.fire(
                "Booking success",
                "Booking Code: " + res.data.data.code,
                "success"
              ).then(() => {
                liff
                  .sendMessages([
                    {
                      type: "text",
                      text: "Booking Code: " + res.data.data.code,
                    },
                  ])
                  .then(function () {
                    liff.closeWindow();
                  });
              });
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                text: "The selected time slot is not available for booking. please try again",
              }).then(() => {
                liff.closeWindow();
              });
            });
        } else {
          setIsLoading(false);
        }
      });
    }
  };

  return (
    <>
      <p className="font-bold mb-[36px]">Booking summary</p>
      <div className="space-y-[15px] pb-[120px]">
        <p>
          <span className="font-bold mr-[10px]">Name : </span>
          <span>{`${input.first_name} ${input.last_name}`}</span>
        </p>
        <p>
          <span className="font-bold mr-[10px]">Phone number : </span>
          <span>{formatPhoneNumber(input.tel)}</span>
        </p>
        <p>
          <span className="font-bold mr-[10px]">Email : </span>
          <span>{input.email || "-"}</span>
        </p>
        <p>
          <span className="font-bold mr-[10px]">Booking date : </span>
          <span>
            {dayjs(new Date(input.booking_date)).format("DD/MM/YYYY")}
          </span>
        </p>
        <p>
          <span className="font-bold mr-[10px]">Booking time : </span>
          <span>{time}</span>
        </p>
        <div>
          <p className="font-bold mb-[15px]">Services</p>
          <div>
            {input.services.map((s, index) => {
              let service = services.filter((sv) => sv.id === s)[0];
              return (
                <p>{`${index + 1}. ${service.name} ( ${
                  service.duration
                } mins )`}</p>
              );
            })}
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 w-full left-0">
        <div className="py-[35px] px-[15px] bg-white  grid grid-cols-2">
          <button
            type="button"
            className="text-[#109747] body-b"
            onClick={() => onChangeStep(1)}
          >
            Cancel
          </button>
          <button
            disabled={isLoading}
            className="text-white bg-[#109747] disabled:bg-light-gray body-b rounded-[8px] h-[45px]"
            onClick={onBooking}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};

export default Summary;
