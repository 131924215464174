import { FaSearch, FaStar } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";

import CouponCard from "components/coupon/CouponCard";
import CouponDetail from "components/coupon/CouponDetail";
import CouponServices from "services/Coupon";
import { ICoupon } from "Interfaces/Coupon";
import { IRedeem } from "Interfaces/Redeem";
import LoadingSection from "components/LoadingSection";
import ProfileServices from "services/Profile";
import Swal from "sweetalert2";
import { UserContext } from "contexts/UserContext";
import dayjs from "dayjs";

const CouponList = () => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [popup, setPopup] = useState<boolean>(false);
  const [coupons, setCoupons] = useState<ICoupon[] | null>(null);
  const [couponSelected, setCouponSelected] = useState<ICoupon | null>(null);
  const { user, token, onSetUser } = useContext(UserContext);

  useEffect(() => {
    let delaySearch: any;
    delaySearch = setTimeout(() => {
      CouponServices.getAll(token as string, searchInput).then((res) => {
        setCoupons(res.data.data);
      });
    }, 500);
    return () => {
      clearTimeout(delaySearch);
    };
  }, [token, searchInput]);

  const handlePopup = (isShow: boolean, coupon: ICoupon | null) => {
    setPopup(isShow);
    setCouponSelected(coupon);
  };

  const handleSubmit = () => {
    if (couponSelected?.confirm_code) {
      onAskConfirmCode((confirmCode) => {
        onConfirmSubmit(() => onSubmit(confirmCode));
      });
    } else {
      onConfirmSubmit(onSubmit);
    }
  };

  const onSubmit = (confirmCode?: string) => {
    CouponServices.redeem(
      couponSelected?.id as number,
      token as string,
      confirmCode ? { confirm_code: confirmCode } : undefined
    )
      .then((res) => {
        let redeem: IRedeem = res.data.data;
        ProfileServices.getProfile(token as string).then((resProfile) => {
          let userRes = resProfile.data.data;
          onSetUser(userRes);
        });
        CouponServices.getAll(token as string, searchInput).then((res) => {
          setCoupons(res.data.data);
        });
        onSuccess(redeem)
      })
      .catch((err) => {
        let text = "ไม่สามารถแลกคูปองได้";
        if (err.response.data.message === "coupon confirm code is invalid") {
          text = "รหัสยืนยันไม่ถูกต้อง";
        }
        onError(text)
      });
  };

  const handleSubmitSpecial = () => {
    Swal.fire({
      html: `
      <p class="text-[16px] font-bold text-black mb-[20px]">จำนวนคะแนนที่ต้องการใช้</p>
      <input type="number" id="point" class="px-[15px] py-[8px] border border-gray-25 outline-none" min="1" placeholder="คะแนน">`,
      confirmButtonText: "ยืนยัน",
      confirmButtonColor: "#13BD57",
      focusConfirm: false,
      showCloseButton: true,
      preConfirm: () => {
        const point = (Swal.getPopup()?.querySelector("#point") as any).value;
        if (!point) {
          Swal.showValidationMessage(`กรุณาใส่คะแนน`);
        }
        return { point: point };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const point = parseInt(result.value?.point);
        if (couponSelected?.confirm_code) {
          onAskConfirmCode((confirm_code) => {
            onConfirmSubmit(() => {
              onSubmitSpecial(point, confirm_code);
            });
          });
        } else {
          onConfirmSubmit(() => {
            onSubmitSpecial(point);
          });
        }
      }
    });
  };

  const onSubmitSpecial = (point: number, confirmCode?: string) => {
    CouponServices.redeemSpecial(
      couponSelected?.id as number,
      token as string,
      {
        point,
        confirm_code: confirmCode,
      }
    )
      .then((res) => {
        let redeem: IRedeem = res.data.data;
        ProfileServices.getProfile(token as string).then((resProfile) => {
          let userRes = resProfile.data.data;
          onSetUser(userRes);
        });
        CouponServices.getAll(token as string, searchInput).then((res) => {
          setCoupons(res.data.data);
        });
        onSuccess(redeem)
      })
      .catch((err) => {
        let text = "ไม่สามารถแลกคูปองได้";
        if (err.response.data.message === "coupon confirm code is invalid") {
          text = "รหัสยืนยันไม่ถูกต้อง";
        }
        onError(text)
      });
  };

  const onAskConfirmCode = (onConfirm: (confirm_code: string) => void) => {
    Swal.fire({
      html: `
      <p class="text-[16px] font-bold text-black mb-[4px]">กรุณากรอกรหัสยืนยันแลกของรางวัล</p>
      <p class="text-[16px] font-bold text-black mb-[20px]">(สำหรับพนักงาน)</p>
      <input type="text" id="confirm_code" class="px-[15px] py-[8px] border border-gray-25 outline-none" min="1" placeholder="กรอกรหัสยืนยัน">`,
      confirmButtonText: "ยืนยัน",
      confirmButtonColor: "#13BD57",
      focusConfirm: false,
      showCloseButton: true,
      preConfirm: () => {
        const confirm_code = (
          Swal.getPopup()?.querySelector("#confirm_code") as any
        ).value;
        if (!confirm_code) {
          Swal.showValidationMessage(`กรุณาใส่รหัสยืนยัน`);
        }
        return { confirm_code: confirm_code };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm(result?.value?.confirm_code || "");
      }
    });
  };

  const onConfirmSubmit = (onConfirm: () => void) => {
    Swal.fire({
      icon: "question",
      html: `<p class="label-m">เมื่อยืนยันจะถือว่าเป็นการใช้งานคูปองทันที <br>ต้องการยืนยันการใช้งานคูปองนี้ ใช่หรือไม่ ?</p>`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      confirmButtonColor: "#13BD57",
      cancelButtonText: `<span class="text-light-green">ยกเลิก</span>`,
      reverseButtons: true,
      cancelButtonColor: "white",
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }
    });
  };

  const onSuccess = (redeem: IRedeem) => {
    Swal.fire({
      title: "ยืนยันการทำรายการ",
      html: `<p class="heading mb-3">รายละเอียดคูปอง</p>
        <p class="text-light-green text-[30px] font-bold mb-3">${
          redeem.code
        }</p>
        <p class="label-m">
        คูปอง : ${redeem.coupon.name}<br> 
        จำนวนที่ใช้ : ${(
          redeem.coupon.point || redeem.point_used || 0
        ).toLocaleString()} คะแนน<br> 
        ใช้งานเมื่อ : ${dayjs(redeem.created_at).format(
          "DD/MM/YYYY HH:mm"
        )}
        </p>`,
      confirmButtonText: `<span class="text-white">เสร็จสิ้น</span>`,
      confirmButtonColor: "#13BD57",
    });
  };

  const onError = (text: string) => {
    Swal.fire({
      html: `<p class="text-[16px] font-bold text-black mb-[20px]">${text}</p>`,
      confirmButtonText: "ตกลง",
      confirmButtonColor: "#13BD57",
    });
  };

  return (
    <div>
      <div className="flex relative mb-[25px]">
        <FaSearch className="absolute top-[10.5px] left-[12px] text-gray text-[14px]" />
        <input
          className="bg-heavy-white w-full h-[35px] pl-[35px] pr-[12px] placeholder-gray text-gray focus:outline-green rounded-[8px]"
          placeholder="ค้นหา"
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
      <div className="flex items-center justify-between h-[68px] px-[22px] mb-[20px] box">
        <div className="flex items-center">
          <FaStar className="text-light-green text-[30px] mr-[15px]" />
          <p className="body-b">คะแนนสะสม</p>
        </div>
        <p className="title">{user?.point.toLocaleString()}</p>
      </div>
      <div className="space-y-[16px] mb-[34px]">
        {coupons ? (
          <>
            {coupons.length > 0 ? (
              <>
                {coupons.map((coupon: ICoupon) => {
                  return (
                    <div onClick={() => handlePopup(true, coupon)}>
                      <CouponCard coupon={coupon} />
                    </div>
                  );
                })}
              </>
            ) : (
              <p className="label-m">ไม่มีคูปอง</p>
            )}
          </>
        ) : (
          <LoadingSection />
        )}
      </div>
      <p className="label-b text-black mb-[7px]">เงื่อนไขการสะสมคะแนน</p>
      <ul className="mb-[20px]">
        <li className="caption-m list-disc">
          ยอดสั่งซื้อสะสมจะเริ่มเก็บตั้งแต่วันที่ลูกค้าทำการลงทะเบียน
          และยอดจะหมดอายุวันที่ 31 ธันวาคม ของทุกปี
        </li>
        <li className="caption-m list-disc">
          การสะสมคะแนนจะเป็นสินค้าราคาปกติ ที่ไม่ร่วมโปรโมชั่น
        </li>
      </ul>
      <p className="remark text-right text-red">
        วันครบกำหนด 31/12/{new Date().getFullYear()}
      </p>
      <CouponDetail
        popup={popup}
        handlePopup={handlePopup}
        handleSubmit={handleSubmit}
        handleSubmitSpecial={handleSubmitSpecial}
        coupon={couponSelected}
      />
    </div>
  );
};

export default CouponList;
