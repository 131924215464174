import axios from "axios";

const ogBookingService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/og-booking`,
});

const OGBookingServices = {
  booking: (
    data: {
      line_id: string;
      first_name: string;
      last_name: string;
      email?: string;
      tel: string;
      booking_date: string;
      services: number[];
      start_slot_time_id: number;
    }
  ) =>
    ogBookingService.post(``, data),
};

export default OGBookingServices;