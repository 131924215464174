import axios from "axios";

const OGTimeSlotService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/og-time-slot`,
});

const OGTimeSlotServices = {
  getAll: (data: { line_id: string; date: string }) =>
    OGTimeSlotService.post(`/list`, data),
};

export default OGTimeSlotServices;
